import {Helmet} from "react-helmet"
import shareImg from "../assets/images/meta/share.png"
import Background from "../components/Background/Background"
import nightfallHeroImg from "../assets/images/graphics/nightfallHero.png"
import nightfallTopImg from "../assets/images/graphics/nightfall-top.jpg"
import eventDiscoImg from "../assets/images/graphics/discoDonutz.jpeg"
import eventIcarusImg from "../assets/images/graphics/event-icarus.jpg"

import Nav from "../components/Nav/Nav"
import Footer from "../components/Footer/Footer"
import {Link} from "gatsby"
import * as React from "react"

const NightfallPage = ({location}) => {
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Nightfall | Nocturne X</title>
                <meta name="description"
                      content="Enter an otherwordly forest of sound and light on planet Nocturne X in this transformative multi-sensory art experience, where the alien environment reacts to your every touch and movement."/>
                <meta property="og:image" content={shareImg}/>
                <link rel="canonical" href="https://nocturne-x.com"/>
            </Helmet>

            <Background/>

            <Nav />

            <div className="Hero">
                <img
                    src={nightfallHeroImg}
                    alt="Nocturne X"
                    className="Hero__graphic"
                />

            </div>

            <div class="container">

                <div className="Section" id="music">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <h1 class="Text--red">Music, immersive theater, and more</h1>
                            <p>
                                Come see Nocturne X in a completely different light with <strong>Nightfall!</strong> With different events every month, dance the night away on a journey through space-time with our weekend night programming just for adults. Come see this expansive exhibition light up with live DJ performances, immersive theater experiences and more. Grab a drink and reunite with friends under Nocturne X’s eight moons and immerse yourself in something new. 
                            </p>
                        </div>
                        <div class="col-12 col-lg-6">
                            <img src={nightfallTopImg} class="Event--img" />
                        </div>
                    </div>
                </div>

                <div className="Section" id="events">
                    <h1 class="Text--yellow">
                        Upcoming Nightfall Events
                    </h1>

                    <div class="row Event--row">
                        <div class="col-12 col-lg-5">
                            <img src={eventDiscoImg}/>
                        </div>
                        <div class="col-12 col-lg-7">
                            <p class="Event--date">
                                FRIDAY, DECEMBER 17
                            </p>
                            <p class="Event--header">
                                Disco Donutz: Aliens, Oddballs & Weirdos
                            </p>
                            <p class="Event--body">
                                Disco Donutz, the team behind the Heavy Petting Zoo art car, is taking an interstellar party bus filled with Aliens, Oddball & Weirdos to Nocturne X! Calling all Aliens, Oddballs and Weirdos! Tired of living on Planet Earth disguised as a human? Is Earth simply not fabulous enough for your fun and fantastic self? We know the feeling! Join Disco Donutz as we take an interstellar party bus to Nocturne X, where all creatures, all identities and all life forms are welcome to explore, dance and be themselves. Please leave your human costumes at home and come as the Aliens, Oddballs and Weirdos you were born to be!
                            </p>
                            <a
                                className="Button Button--red"
                                href="https://grayarea.org/event/nightfall-disco-donutz/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Get Ticket
                            </a>

                        </div>
                    </div>
                    


                </div>

            </div>

            <Footer />

        </main>
    )
}

export default NightfallPage
